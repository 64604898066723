.box {
  position: relative;
  overflow: hidden;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
}

.img {
  position: absolute;
}

.line {
  position: absolute;
  top: -5%;
  width: 100%;
  height: 8%;
  background: #070518;
  animation: scan 2s alternate infinite;
}

.bikini {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  animation: mask 2s alternate infinite;
}

@keyframes scan {
  0% {
    top: -5%;
  }
  100% {
    top: 100%;
  }
}

@keyframes mask {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
}
