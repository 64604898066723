.breadcrumbs_item {
    position: relative;
    color: var(--color-violet-text, #b587ff);
    text-overflow: ellipsis;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.active {
        color: var(--color-white, #fff);
    }

    & + .breadcrumbs_item::before {
        position: absolute;
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: var(--color-white, #fff);
        top: calc(50% - 2px);
        left: -12px;
    }
}

.tags_set {
    @media (min-width: 920px) {
        gap: 4px;
    }
}

.tag {
    display: block;
    min-height: 32px;
    padding: 6px var(--Left-Edge-BTN, 12px);
    border-radius: 8px;
    width: auto;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.12);
    color: var(--color-white, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    transition: var(--transition-normal, all 0.2s);

    // .tag::first-letter
    &::first-letter {
        text-transform: uppercase;
    }

    // .tag:active
    // .tag.active
    &:active,
    &.active {
        box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.12);
        transition: var(--transition-active, all 0.1s);
    }

    // .tag.button
    &.button {
        cursor: pointer;
    }

    // .tag.violet
    &.violet {
        background: var(--color-violet, #6331b4);

        // .tag.violet.button
        &.button {
            // .tag.violet.button:hover
            @media (hover: hover) {
                &:hover {
                    background: #7e46da;
                }
            }

            // .tag.violet.button:active
            // .tag.violet.button.active
            &:active,
            &.active {
                background: #4d2392;
            }
        }
    }

    // .tag.blue
    &.blue {
        background: #3156b4;

        // .tag.blue.button
        &.button {
            // .tag.blue.button:hover
            @media (hover: hover) {
                &:hover {
                    background: #466fd9;
                }
            }

            // .tag.blue.button:active
            // .tag.blue.button.active
            &:active,
            &.active {
                background: #234291;
            }
        }
    }

    // .tag.pink
    &.pink {
        background: #b131b4;

        // .tag.pink.button
        &.button {
            // .tag.pink.button:hover
            @media (hover: hover) {
                &:hover {
                    background: #d645d9;
                }
            }

            // .tag.pink.button:active
            // .tag.pink.button.active
            &:active,
            &.active {
                background: #8f2391;
            }
        }
    }

    // .tag.yellow
    &.yellow {
        background: #b59031;

        // .tag.yellow.button
        &.button {
            // .tag.yellow.button:hover
            @media (hover: hover) {
                &:hover {
                    background: #d9af45;
                }
            }

            // .tag.yellow.button:active
            // .tag.yellow.button.active
            &:active,
            &.active {
                background: #917323;
            }
        }
    }

    // .tag.orange
    &.orange {
        background: #b57031;

        // .tag.orange.button
        &.button {
            // .tag.orange.button:hover
            @media (hover: hover) {
                &:hover {
                    background: #d96945;
                }
            }

            // .tag.orange.button:active
            // .tag.orange.button.active
            &:active,
            &.active {
                background: #913d23;
            }
        }
    }
}

.blog_list {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }
}

.blog_item {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: var(--Corner-BTN, 24px);
    overflow: hidden;
    transition: var(--transition-normal, all 0.2s);

    // .blog_item:hover
    @media (hover: hover) {
        &:hover {
            background: rgba(132, 76, 176, 0.1);
        }
    }

    &::after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        box-shadow: inset 0 0 0 2px rgba(132, 76, 176, 0.2);
        pointer-events: none;
    }

    // .blog_item__cover
    &__cover {
        width: 100%;
        aspect-ratio: 450/226;
        object-fit: cover;
    }

    // .blog_item__title
    &__title {
        color: var(--color-white, #fff);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    // .blog_item__date
    &__date {
        color: rgba(255, 255, 255, 0.72);
        text-align: right;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
    }

    // .blog_item__content
    &__content {
        padding: 20px;
        height: 100%;

        @media (max-width: 767px) {
            padding: 16px;
        }
    }

    // .blog_item__props
    &__props {
        margin-top: auto;
        align-items: center;
        justify-content: space-between;
    }
}
