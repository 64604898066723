.slider_box {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    margin: 0px -12px;
    padding: 5px 12px 0;
    width: calc(100% + 24px);
    user-select: none;

    @media (min-width: 921px) {
        // position: relative;
        margin: 0px 0 0 -40px;
        padding: 0 0 0 40px;
        width: calc(100% + 40px);
        height: 100%;
        mask-image: linear-gradient(to left, transparent 0px, black 100px);
    }

    @media (orientation: landscape) {
        min-height: 300px;
    }
}

.slider {
    display: flex;
    flex-direction: row;
    gap: 0;
    // height: 85%;
    flex: 1;
}

.viewport {
    width: 100%;
}

.pagination {
    position: relative;
    margin: auto;
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 1;
}

.pagination_dot {
    background-color: rgba(156, 136, 188, 0.2);
    border-radius: 8px;
    opacity: 1;
    height: 8px;
    width: 16px;
    transition: all 0.3s ease-in-out;

    // .pagination_dot._active
    &._active {
        background-color: #e86f00;
        width: 48px;
    }
}

.item {
    position: relative;
    aspect-ratio: 1 / 1;
    margin-right: 12px;
    max-width: calc(100vw - 24px);
    max-height: calc(100vw - 24px);
    width: auto;
    height: 100%;
    border-radius: var(--Corner-BTN, 16px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: opacity 0.3s;

    @media (min-width: 921px) {
        height: 80%;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 40px;
    }
}

.inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    flex-shrink: 0;
    border-radius: inherit;
}

.photo_block {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    background: rgba(132, 76, 176, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: inherit;

    // .photo_block .tag
    .tag {
        position: absolute;
        top: 2.22vmin;
        right: 2.22vmin;
        padding: 0 1.11vw 0 2.22vmin;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.22vmin;
        font-size: 3.22vmin;
        font-weight: 700;
        line-height: 142.857%;
        border-radius: 2.22vmin;
        border: 0px solid var(--color-gray-2-st-btn, rgba(156, 136, 188, 0.2));
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(12px);
        z-index: 3;
        color: var(--color-white, #fff);

        @media (min-width: 921px) {
            top: 12px;
            right: 18px;
            padding: 0 4px 0 8px;
            gap: 4px;
            font-size: 20px;
            border-radius: 8px;
        }

        // .photo_block .tag span
        span {
            opacity: 0.72;
        }

        // .photo_block .tag .icon
        .icon {
            font-size: 4.22vmin;
            font-weight: 400;

            @media (min-width: 921px) {
                font-size: 24px;
            }
        }
    }

    // .photo_block .scan_line
    .scan_line {
        animation: scan_line 4s infinite ease-in-out;
        animation-direction: alternate;
        background: transparent;
        mix-blend-mode: overlay;
        height: 20px;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
        background: radial-gradient(
            737.37% 50% at 50% 50%,
            #fff 0%,
            rgba(255, 255, 255, 0.1) 80%,
            rgba(121, 96, 159, 0) 100%
        );
        height: 100px;
        width: 100%;
        z-index: 1;
    }

    // .photo_block .scan_line_white
    .scan_line_white {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: radial-gradient(737.37% 50% at 50% 50%, #fff 0%, rgba(243, 236, 255, 0) 13.54%);
        animation: scan_line_white 4s infinite ease-in-out;
        animation-direction: alternate;
        height: 20px;
        width: 100%;
        opacity: 0.32;
        top: calc(0% + 40px);
        z-index: 1;
    }

    // .photo_block .girl_default
    .girl_default {
        // width: 100%;
        // height: 100%;
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        // object-fit: cover;
        content-visibility: auto;
    }

    // .photo_block .girl_nudes
    .girl_nudes {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        -webkit-mask-image: url(../image/mask2.png);
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 100% 0;
        mask-size: 100% 0;
        mask-image: url(../image/mask2.png);
        -webkit-mask-position: 50% 0;
        mask-position: 50% 0;
        mask-repeat: no-repeat;
        animation: girl_nudes 4s infinite ease-in-out;
        content-visibility: auto;

        // .photo_block .girl_nudes__img
        &__img {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            object-fit: cover;
            content-visibility: auto;
        }
    }

    .girl_pose {
        @extend .girl_nudes;
        animation: girl_pose 8s infinite ease-in-out;

        &__2 {
            mask-position: 50% 100%;
            animation-delay: 2s;
        }

        &__3 {
            animation-delay: 4s;
        }
    }
}

@keyframes scan_line {
    0% {
        top: calc(0% - 50px);
    }

    50% {
        top: calc(100% - 50px);
    }

    100% {
        top: calc(0% - 50px);
    }
}

@keyframes scan_line_white {
    0% {
        top: calc(0% - 10px);
    }

    50% {
        top: calc(100% - 10px);
    }

    100% {
        top: calc(0% - 10px);
    }
}

@keyframes girl_nudes {
    0% {
        -webkit-mask-size: 100% 0;
    }

    50% {
        -webkit-mask-size: 100% 100%;
    }

    100% {
        -webkit-mask-size: 100% 0;
    }
}

@keyframes girl_pose {
    0% {
        -webkit-mask-size: 100% 0;
    }

    25% {
        -webkit-mask-size: 100% 100%;
    }

    50%,
    100% {
        -webkit-mask-size: 100% 0;
    }
}
