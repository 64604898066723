.track {
  display: flex;
  overflow: auto;
  justify-content: center;
}

.frame {
  height: 50px;
  width: 64px;
  border: 1px solid #5d3d9c;
  background-image: url(/images/video-frame.svg);
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
  padding: 9px 1px;
  background-repeat: no-repeat;
  background-size: cover;
}

.img {
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
