.slider_box {
    margin: auto;

    @media (max-width: 920px) {
        margin: 0 -12px;
    }
}

.slider {
    overflow: hidden;
    width: 100%;
    max-height: 500px;
    border-radius: var(--Corner-BTN, 16px);

    @media (max-width: 920px) {
        border-radius: 0;
        max-height: 240px;
    }
}

.viewport {
    backface-visibility: hidden;
    touch-action: pan-y;
}

.item {
    position: relative;
    flex: 0 0 100%;
    min-width: 0;
    background: var(--color-notif-bg, #844cb029);

    img {
        min-height: 100px;
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin: auto;
    }
}

.pagination_box {
    position: relative;
}

.pagination_arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 920px) {
        right: 12px;
    }
}