.box {
  position: fixed;
  top: 170px;
  right: 12px;
  background: var(--color-btn-dark-hover-80, #26212bcc);
  padding: 8px;
  border-radius: 8px;
  z-index: 1;
}

.try_block {
  display: none;

  .girl_video {
    width: 160px;
    user-select: none;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (min-width: 1285px) {
  .box {
    top: 212px;
    right: calc(50% - 630px);
    background: none;
  }

  .title {
    display: none;
  }

  .try_block {
    display: flex;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 48px;
    overflow: hidden;
    transition: height 0.3s;

    @media (hover: hover) {
      &:hover {
        height: 104px;
      }
    }
  }

  .logo {
    display: none;
  }
}
