.button_anim_wrapper {
  position: relative;
  border-radius: 16px;

  &::before {
    position: absolute;
    content: "";
    display: block;
    inset: 1px;
    border-radius: inherit;
    background: linear-gradient(
      65deg,
      transparent 0% 20px,
      rgba(255, 255, 255, 0.2) 20px 60px,
      transparent 60px 100%
    );
    background-repeat: no-repeat;
    background-size: 200% 100%;
    background-position: 100% 100%;
    animation: shine 3s infinite linear;
    mask-image: linear-gradient(to top, transparent, black);
    z-index: 1;
    pointer-events: none;
  }
}

@keyframes shine {
  0%,
  30% {
    background-position: 100% 100%;
  }
  60%,
  100% {
    background-position: -100% 100%;
  }
}
