.banner {
  position: relative;
  border-radius: 24px;
  overflow: hidden;
}

.gril {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: auto;
  height: 100%;
  object-fit: cover;

  &.left {
    left: 0;
    mask-image: linear-gradient(to right, black 0 50%, transparent 90% 100%);
    translate: -40%;
    z-index: -1;
  }

  &.right {
    right: 0;
    mask-image: linear-gradient(to left, black 0 50%, transparent 90% 100%);
    translate: 40%;
    z-index: -1;
  }

  @media (min-width: 921px) {
    &.left {
      translate: 0;
      z-index: 0;
    }

    &.right {
      translate: 0;
      z-index: 0;
    }
  }
}
