.range_box {
  position: absolute;
  bottom: 0;
  height: 64px;
  // padding: 0 20px;
  // border-left: 20px solid transparent;
  // border-right: 20px solid transparent;

  // .range_box.with_bg
  &.with_bg {
    background: var(--Color-Notif-BG, rgba(132, 76, 176, 0.16));
  }
}

@mixin thumb {
  background: var(--color-orange-main);
  border: none;
  cursor: ew-resize;
  height: 56px;
  width: 20px;
  pointer-events: all;
  position: relative;
  touch-action: none;
  transition: 0.3s;
  z-index: -1;

  @media (hover: hover) {
    &:hover {
      background: var(--color-orange-main--hover);
    }
  }

  &:active {
    background: var(--color-orange-main--active);
  }
}

@mixin playline {
  width: 4px;
  height: 52px;
  background: var(--color-white);
  border-radius: 2px;
  box-shadow: 0 0 0 1px #00000080;
  transition: 0.1s linear;
  z-index: 2;
  pointer-events: none;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  padding: 0;
  margin: 0;
  z-index: 2;
  transition: 0.3s;

  // .thumb
  // .thumb::-webkit-slider-thumb
  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  // .thumb::-webkit-slider-thumb
  &::-webkit-slider-thumb {
    @include thumb();
  }

  // .thumb::-moz-range-thumb
  &::-moz-range-thumb {
    @include thumb();
  }

  // .thumb::-ms-thumb
  &::-ms-thumb {
    @include thumb();
  }

  // .thumb::-moz-range-track
  &::-moz-range-track {
    position: relative;
    z-index: -1;
    background-color: transparent;
    border: 0;
  }

  // .thumb:last-of-type::-moz-range-track
  &:last-of-type::-moz-range-track {
    -moz-appearance: none;
    background: none transparent;
    border: 0;
  }

  // .thumb::-moz-focus-outer
  &::-moz-focus-outer {
    border: 0;
  }

  &.left {
    &::-webkit-slider-thumb {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &::-moz-range-thumb {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &::-ms-thumb {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &.right {
    &::-webkit-slider-thumb {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &::-moz-range-thumb {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &::-ms-thumb {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &.play_line {
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);

    // .thumb::-webkit-slider-thumb
    &::-webkit-slider-thumb {
      @include playline();
    }

    // .thumb::-moz-range-thumb
    &::-moz-range-thumb {
      @include playline();
    }

    // .thumb::-ms-thumb
    &::-ms-thumb {
      @include playline();
    }

    &._hide {
      opacity: 0;
    }
  }
}

.slider {
  position: relative;
  width: 100%;
  height: 56px;
  will-change: auto;
}

.track,
.range {
  position: absolute;
  height: inherit;
}

.track {
  width: 100%;
  z-index: 1;
}

.range {
  border-top: 3px solid var(--color-orange-main);
  border-bottom: 3px solid var(--color-orange-main);
  z-index: 1;
  will-change: auto;
}

.playline {
  position: absolute;
  width: 4px;
  height: 52px;
  background: var(--color-white);
  border-radius: 2px;
  box-shadow: 0 0 0 1px #00000080;
  transition: 0.1s linear;
}

.cost {
  position: absolute;
  width: 100px;
  min-width: fit-content;
  height: auto;
  inset: auto 0 4px 0;
  will-change: auto;
  background: var(--color-orange-main);
  padding: 2px 4px;
  border-radius: 4px 4px 0 0;
  filter: drop-shadow(0px -3px 6px black);
  z-index: 3;
  pointer-events: none;

  // .cost .text
  .text {
    color: #000000 !important;
    white-space: nowrap;
  }
}
